.navBackgroundBlack {
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.85) 100%
	);
}
.navBackgroundBrown {
	background: linear-gradient(
		0deg,
		rgba(115, 99, 87, 0) 20%,
		rgba(115, 99, 87, 1) 100%
	);
}
.navBackgroundMobile {
	background: rgba(0, 0, 0, 0.8);
}
.navIcon {
	margin-right: 12px;
}
.navLogo {
	margin-top: 16px;
}
.navItem {
	margin-top: 6px;
}
.skipBtn {
	font-family: 'MarkProMedium';
	text-transform: uppercase;
	font-size: 14px;
	margin-top:16px;
	color: #ffe297;
}
.navBtn {
	font-family: 'MarkProMedium';
	text-transform: uppercase;
	padding-bottom: 6px;
	border-width: 0 0 1.5px;
	border-style: solid;
	font-size: 14px;
}
.navBtnOver {
	border-color: #ffffff;
	text-transform: none;
	color: #ffffff;
}

.navBtnColor1 {
	border-color: #ffe297;
	color: #ffe297;
}
.navBtnColor2 {
	border-color: #d1ac68;
	color: #d1ac68;
}
.navBtnColor3 {
	border-color: #fbae17;
	color: #fbae17;
}
.navBtnColor4 {
	border-color: #c38314;
	color: #c38314;
}
.navBtnColor5 {
	border-color: #ffffff;
	color: #ffffff;
}

.pageBtn {
	font-family: 'MarkProMedium';
	padding-top: 12px;
	text-transform: none;
	padding-bottom: 8px;
	border-width: 0 0 2px;
	border-style: solid;
	border-color: #544112;
	font-size: 18px;
	color: #604709;
	text-transform: uppercase;
}
.pageBtnOver {
	border-color: #ffe297;
	text-transform: uppercase;
	font-size: 18px;
	color: #8f6b0f;
}
.pageLightBtn {
	border-color: #ffe297;
	text-transform: uppercase;
	color: #ffe297;
}
.pageLightBtnOver {
	border-color: #ffe297;
	text-transform: uppercase;
	font-size: 18px;
	color: #8f6b0f;
}

.videoLightBtn {
	opacity: 0;
	border-color: #ffe297;
	text-transform: uppercase;
	color: #ffe297;
}
.videoLightBtnOver {
	opacity: 1;
	border-color: #ffe297;
	text-transform: uppercase;
	color: #ffe297;
}
