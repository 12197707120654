.App {
}
body {
	background-color: '#19130D';
}
canvas {
	outline: none;
	border: none;
	//pointer-events: none;
}

.pano-container {
	position: fixed;
	z-index: -10;
	width: 100%;
	height: 100%;
	//pointer-events: none;
}
.video-intro-container {
	position: fixed;
	z-index: -10;
	width: 100%;
	height: 100%;
	//pointer-events: none;
}
.video-product-container {
	//position: fixed;
	z-index: 10;
	width: 100%;
	height: 100%;
	//pointer-events: none;
}

ion-page {
	//pointer-events: none;
}
ion-grid {
	//height: 100%;
	//justify-content: center;
	//align-items: center;
	//pointer-events: none;
}
ion-row {
	//height: 100%;
	//justify-content: center;
	//align-items: center;
	//pointer-events: none;
}
ion-button {
	--box-shadow: none;
}

ion-card-header {
	//pointer-events: none;
}
ion-card-title {
	//text-align: center;
	//pointer-events: none;
}
ion-card-subtitle {
	//text-align: center;
	//pointer-events: none;
}
ion-title {
}
ion-content {
	//text-align: center;
	--ion-background-color: transparent;
	//pointer-events: none;
	//--ion-background-color: #ffffff;
}

ion-app {
	//pointer-events: none;
}
ion-item-group {
	//pointer-events: none;
}
