.shelfBackgroundDesktop {
	//background-image: url('../assets/shelf/shelfdesktop8.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.shelfBackgroundMobile {
	//background-image: url('../assets/shelf/shelfmobile8.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	//width: '100%';
	//height: '100%';
	background-position: top;
	background-size: cover;
}
