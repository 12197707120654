.productItemIcon {
	margin-right: 15px;
}

.productSizeTitle {
	font-family: 'MarkProBook';
	font-size: 14px;
	//padding-bottom: 10px;
}
.productQuantityTitle {
	font-family: 'MarkProBook';
	font-size: 14px;
	//padding-bottom: 10px;
}
.productCount {
	font-family: 'MarkProMedium';
	font-size: 21px;
	//justify-content: center;
	//align-items: center;
	//text-align: center;
	//padding-bottom: 10px;
}
.productTitle {
	font-family: 'MarkProBook';
	text-transform: none;
	padding-top: '6px';
	padding-bottom: 6px;
	border-width: 0 0 1.5px;
	border-style: solid;
	font-size: px;
	border-color: #d2b55e;
	text-transform: uppercase;
	color: '#000000';
}

.productPriceDiscounted {
	font-family: 'MarkProMedium';
	font-size: 21px;
	-webkit-text-decoration-line: line-through;
	text-decoration-line: line-through;
}
.productPrice {
	font-family: 'MarkProMedium';
	font-size: 21px;
}
.productVolume {
	font-family: 'MarkProMedium';
	font-size: 21px;
	color: 'black';
}

.productHeroTitle {
	font-family: 'MarkProBook';
	font-size: 16px;
	//height: 200px;
	text-align: center;
	color: '#43340C';
	padding-left: 8px;
	text-transform: uppercase;
	padding-right: 8px;
}
