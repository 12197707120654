@font-face {
	font-family: 'MarkProBook';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/MarkPro-Medium.eot');
	src: url('../fonts/MarkPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MarkPro-Medium.woff2') format('woff2'),
		url('../fonts/MarkPro-Medium.woff') format('woff'),
		url('../fonts/MarkPro-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'MarkProMedium';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/MarkPro-Medium.eot');
	src: url('../fonts/MarkPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MarkPro-Medium.woff2') format('woff2'),
		url('../fonts/MarkPro-Medium.woff') format('woff'),
		url('../fonts/MarkPro-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'MarkProLight';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/MarkPro-Light.eot');
	src: url('../fonts/MarkPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MarkPro-Light.woff2') format('woff2'),
		url('../fonts/MarkPro-Light.woff') format('woff'),
		url('../fonts/MarkPro-Light.otf') format('opentype');
}

@font-face {
	font-family: 'MrsEavesItalic';
	font-style: normal;
	font-weight: normal;
	src: url('../fonts/MrsEaves-Italic.eot');
	src: url('../fonts/MrsEaves-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MrsEaves-Italic.woff2') format('woff2'),
		url('../fonts/MrsEaves-Italic.woff') format('woff'),
		url('../fonts/MrsEaves-Italic.otf') format('opentype');
}
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
