.coachTitle {
	font-family: 'Kalam';
	font-size: 20px;
	color: white;
}
.coachArrow {
	margin-top: 1px;
	margin-right: 16px;
	margin-left: 16px;
}
.disable-select {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	-webkit-touch-callout: none;
}
