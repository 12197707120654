@import url(https://fonts.googleapis.com/css2?family=Kalam&display=swap);
.closeBtn{font-family:"MarkProMedium";text-transform:none;padding-bottom:8px;border-width:0 0 2px;border-style:solid;border-color:#544112;font-size:26px;color:#604709}.closeIcon{color:#604709;fill:#604709}.closeBtnOver{border-color:#ffe297;text-transform:none;font-size:26px;color:#8f6b0f}.videosBackground{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.videoTitle{font-family:"MarkProBook";text-transform:none;text-align:center;padding-bottom:8px;border-width:0 0 1.5px;border-style:solid;font-size:18px;color:#d1ac68;border-color:#fff;margin-left:8px;margin-right:8px}.videoFrame{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.videoBackBee{background-image:url(/static/media/sciencebee@4x.d5485f98.jpg);background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.videoBackComb{background-image:url(/static/media/sciencecomb@4x.f0458634.jpg);background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.mainTitle{font-family:"MarkProBook";text-transform:none;padding-bottom:6px;border-width:0 0 1.5px;border-style:solid;font-size:24px;margin:32px;border-color:#d2b55e;text-transform:uppercase;color:"#544112"}.metalTitle{font-family:"MarkProMedium";text-transform:none;padding-bottom:6px;border-width:0 0 1.5px;border-style:solid;margin-top:16px;margin-left:32px;margin-right:32px;margin-bottom:16px;border-color:#3f310e;color:#3f310e}.metalBody{font-family:"MrsEavesItalic";margin-left:32px;margin-right:32px;color:#3f310e}
@font-face{font-family:"MarkProBook";font-style:normal;font-weight:normal;src:url(/static/media/MarkPro-Medium.aa379cb9.eot);src:url(/static/media/MarkPro-Medium.aa379cb9.eot) format("embedded-opentype"),url(/static/media/MarkPro-Medium.61d17b12.woff2) format("woff2"),url(/static/media/MarkPro-Medium.ac762b53.woff) format("woff"),url(/static/media/MarkPro-Medium.d71f2bef.otf) format("opentype")}@font-face{font-family:"MarkProMedium";font-style:normal;font-weight:normal;src:url(/static/media/MarkPro-Medium.aa379cb9.eot);src:url(/static/media/MarkPro-Medium.aa379cb9.eot) format("embedded-opentype"),url(/static/media/MarkPro-Medium.61d17b12.woff2) format("woff2"),url(/static/media/MarkPro-Medium.ac762b53.woff) format("woff"),url(/static/media/MarkPro-Medium.d71f2bef.otf) format("opentype")}@font-face{font-family:"MarkProLight";font-style:normal;font-weight:normal;src:url(/static/media/MarkPro-Light.4a98043c.eot);src:url(/static/media/MarkPro-Light.4a98043c.eot) format("embedded-opentype"),url(/static/media/MarkPro-Light.5308f8eb.woff2) format("woff2"),url(/static/media/MarkPro-Light.478dd278.woff) format("woff"),url(/static/media/MarkPro-Light.b52b95d1.otf) format("opentype")}@font-face{font-family:"MrsEavesItalic";font-style:normal;font-weight:normal;src:url(/static/media/MrsEaves-Italic.bdb80c63.eot);src:url(/static/media/MrsEaves-Italic.bdb80c63.eot) format("embedded-opentype"),url(/static/media/MrsEaves-Italic.905021c1.woff2) format("woff2"),url(/static/media/MrsEaves-Italic.268bf79d.woff) format("woff"),url(/static/media/MrsEaves-Italic.6a6f93c5.otf) format("opentype")}
*{box-sizing:border-box}html,body,#root{width:100%;height:100%;margin:0;padding:0;touch-action:pan-x pan-y}body{background:#fff;overflow:hidden;overscroll-behavior-y:none;touch-action:pan-x pan-y}.loading{padding:10px;-webkit-transform:translate3d(-50%, -50%, 0);transform:translate3d(-50%, -50%, 0)}

.homeBackground{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.metalBackground{background-repeat:no-repeat;width:"100%";height:"100%";background-position:center;background-size:cover}.blended{mix-blend-mode:hard-light}.blendedOver{mix-blend-mode:screen}.welcome{font-family:"MarkProLight";font-size:24px;color:#3f310e}.wellness{font-family:"MarkProLight";font-size:30px;margin-top:5px;color:#3f310e;line-height:.5}.maori p{font-family:"MrsEavesItalic";font-size:28px;color:#3f310e;line-height:1;margin:8px;margin-left:0px;margin-right:0px}.engraved{-webkit-background-clip:text;-moz-background-clip:text;background-clip:text;text-shadow:-0.5px .5px 0px #ffe297,.5px -0.5px 0px #2b1c07}.homeBtn{font-family:"MarkProMedium";text-transform:none;padding-bottom:8px;border-width:0 0 2px;border-style:solid;border-color:#43340c;font-size:26px;color:#43340c}.newHomeBtn{font-family:"MarkProMedium";text-transform:none;padding-bottom:8px;border-width:0 0 2px;border-style:solid;border-color:#ffe297;font-size:22px;color:#ffe297;text-align:center}.homeIcon{margin-right:12px;color:#604709;fill:#604709}.homeBtnOver{border-color:#ffe297;text-transform:none;font-size:26px;color:#8f6b0f}.newHomeBtnOver{border-color:#ffe297;text-transform:none;font-size:22px;color:#8f6b0f}.preloadingText{margin-top:16px;margin-bottom:5px;font-family:"MarkProMedium";color:"white"}#loadingcontainer{position:relative}#loadingcontainer img{position:absolute;opacity:0}#loadingcontainer img:first-child{opacity:1}
.productItemIcon{margin-right:15px}.productSizeTitle{font-family:"MarkProBook";font-size:14px}.productQuantityTitle{font-family:"MarkProBook";font-size:14px}.productCount{font-family:"MarkProMedium";font-size:21px}.productTitle{font-family:"MarkProBook";text-transform:none;padding-top:"6px";padding-bottom:6px;border-width:0 0 1.5px;border-style:solid;font-size:px;border-color:#d2b55e;text-transform:uppercase;color:"#000000"}.productPriceDiscounted{font-family:"MarkProMedium";font-size:21px;-webkit-text-decoration-line:line-through;text-decoration-line:line-through}.productPrice{font-family:"MarkProMedium";font-size:21px}.productVolume{font-family:"MarkProMedium";font-size:21px;color:"black"}.productHeroTitle{font-family:"MarkProBook";font-size:16px;text-align:center;color:"#43340C";padding-left:8px;text-transform:uppercase;padding-right:8px}
.factsBackgroundDesktop{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.factsTitle{font-family:"MarkProBook";text-transform:none;padding-bottom:6px;border-width:0 0 1.5px;border-style:solid;font-size:20px;border-color:#d2b55e;text-transform:uppercase;color:"#544112"}.factsContent h2{font-family:"MarkProMedium";font-size:16px;padding-bottom:6px;color:#d2b55e}.factsContent p{font-family:"MarkProLight";font-size:16px;padding-bottom:6px}.factsContent{font-family:"MarkProLight";font-size:16px}.factsCard{z-index:999}.factsHeroTitle{font-family:"MarkProBook";font-size:35px;text-align:center;color:"#43340C";padding-left:24px;padding-right:24px}ol{list-style:none;padding-left:16px;counter-reset:olNum}ol li::before{counter-increment:olNum;content:counter(olNum);color:#d2b55e;display:inline-block;width:1em;margin-left:-1.5em;margin-right:.5em;text-align:right;direction:rtl}.carousel-item{width:100%;-webkit-flex:1 1;flex:1 1}
.navBackgroundBlack{background:linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%)}.navBackgroundBrown{background:linear-gradient(0deg, rgba(115, 99, 87, 0) 20%, #736357 100%)}.navBackgroundMobile{background:rgba(0,0,0,.8)}.navIcon{margin-right:12px}.navLogo{margin-top:16px}.navItem{margin-top:6px}.skipBtn{font-family:"MarkProMedium";text-transform:uppercase;font-size:14px;margin-top:16px;color:#ffe297}.navBtn{font-family:"MarkProMedium";text-transform:uppercase;padding-bottom:6px;border-width:0 0 1.5px;border-style:solid;font-size:14px}.navBtnOver{border-color:#fff;text-transform:none;color:#fff}.navBtnColor1{border-color:#ffe297;color:#ffe297}.navBtnColor2{border-color:#d1ac68;color:#d1ac68}.navBtnColor3{border-color:#fbae17;color:#fbae17}.navBtnColor4{border-color:#c38314;color:#c38314}.navBtnColor5{border-color:#fff;color:#fff}.pageBtn{font-family:"MarkProMedium";padding-top:12px;text-transform:none;padding-bottom:8px;border-width:0 0 2px;border-style:solid;border-color:#544112;font-size:18px;color:#604709;text-transform:uppercase}.pageBtnOver{border-color:#ffe297;text-transform:uppercase;font-size:18px;color:#8f6b0f}.pageLightBtn{border-color:#ffe297;text-transform:uppercase;color:#ffe297}.pageLightBtnOver{border-color:#ffe297;text-transform:uppercase;font-size:18px;color:#8f6b0f}.videoLightBtn{opacity:0;border-color:#ffe297;text-transform:uppercase;color:#ffe297}.videoLightBtnOver{opacity:1;border-color:#ffe297;text-transform:uppercase;color:#ffe297}
.cartTitle{font-family:"MarkProMedium";font-size:20px;padding-bottom:10px;color:"#544112";text-transform:uppercase}.cartCountMessage{font-family:"MarkProLight";color:"#808080";text-transform:none;font-size:16px}.cartLineItemTitle{margin-top:10px;font-family:"MarkProMedium";color:"black";font-size:16px}.cartLineItemSize{font-family:"MarkProMedium";color:"#808080";font-size:16px}.cartLineItemPrice{font-family:"MarkProMedium";color:"#808080";font-size:16px}.cartLineItemCount{font-family:"MarkProMedium";color:"#D1AC68";font-size:16px}.cartLineItemTotal{margin-top:16px;margin-bottom:5px;font-family:"MarkProMedium";color:"#808080";font-size:16px}.cartLineItemTotalPrice{margin-top:16px;margin-bottom:5px;font-family:"MarkProMedium";color:"black";font-size:18px}.cartLine{padding-bottom:10px;border-width:0 0 1.5px;border-style:solid;border-color:#d2b55e}.cartItemLine{padding-bottom:10px;border-width:0 0 1px;border-style:solid;border-color:#e6e6e6}.cartCountCircle{width:"20px";height:"20px";-webkit-flex-direction:"column";flex-direction:"column";padding:"5px";border-radius:"20px";background-color:"red"}.cartCountText{font-family:"MarkProMedium";color:"#D1AC68";font-size:16px}
.shelfBackgroundDesktop{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.shelfBackgroundMobile{background-repeat:no-repeat;margin:0px;background-position:top;background-size:cover}
.scienceTitle{font-family:"MarkProMedium";font-size:26px;padding-bottom:10px;color:"#FFE297"}.scienceBody p{font-family:"MarkProLight";font-size:16px;margin-bottom:10px;color:"#FFE297"}.bookBackground{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.scienceBackground{background-repeat:no-repeat;margin:0px;width:"100%";height:"100%";background-position:center;background-size:cover}.scienceIconBackground{background-color:"green";background-image:url(/static/media/scienceiconback.a517e2ef.png);background-repeat:no-repeat;width:200px;height:200px;background-position:center;background-size:contain;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.scienceIcon{position:absolute;width:74px;margin-top:44px;margin-left:64px}.bookIcon{position:absolute;width:84px;margin-top:50px;margin-left:58px}.scienceCircle{position:absolute;margin-top:-33px;margin-left:-33px;width:266px;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}
body{background-color:"#19130D"}canvas{outline:none;border:none}.pano-container{position:fixed;z-index:-10;width:100%;height:100%}.video-intro-container{position:fixed;z-index:-10;width:100%;height:100%}.video-product-container{z-index:10;width:100%;height:100%}ion-button{--box-shadow: none}ion-content{--ion-background-color: transparent}
:root{--ion-background-color: #19130D;--ion-color-primary: #544112;--ion-color-primary-rgb: 56, 128, 255;--ion-color-primary-contrast: #544112;--ion-color-primary-contrast-rgb: 255, 255, 255;--ion-color-primary-shade: #544112;--ion-color-primary-tint: #544112;--ion-color-secondary: #ffe297;--ion-color-secondary-rgb: 61, 194, 255;--ion-color-secondary-contrast: #544112;--ion-color-secondary-contrast-rgb: 255, 255, 255;--ion-color-secondary-shade: #544112;--ion-color-secondary-tint: #544112;--ion-color-tertiary: #808080;--ion-color-tertiary-rgb: 82, 96, 255;--ion-color-tertiary-contrast: #544112;--ion-color-tertiary-contrast-rgb: 255, 255, 255;--ion-color-tertiary-shade: #544112;--ion-color-tertiary-tint: #544112;--ion-color-success: #2dd36f;--ion-color-success-rgb: 45, 211, 111;--ion-color-success-contrast: #ffffff;--ion-color-success-contrast-rgb: 255, 255, 255;--ion-color-success-shade: #28ba62;--ion-color-success-tint: #42d77d;--ion-color-warning: #ffc409;--ion-color-warning-rgb: 255, 196, 9;--ion-color-warning-contrast: #000000;--ion-color-warning-contrast-rgb: 0, 0, 0;--ion-color-warning-shade: #e0ac08;--ion-color-warning-tint: #ffca22;--ion-color-danger: #eb445a;--ion-color-danger-rgb: 235, 68, 90;--ion-color-danger-contrast: #ffffff;--ion-color-danger-contrast-rgb: 255, 255, 255;--ion-color-danger-shade: #cf3c4f;--ion-color-danger-tint: #ed576b;--ion-color-dark: #222428;--ion-color-dark-rgb: 34, 36, 40;--ion-color-dark-contrast: #ffffff;--ion-color-dark-contrast-rgb: 255, 255, 255;--ion-color-dark-shade: #1e2023;--ion-color-dark-tint: #383a3e;--ion-color-medium: #92949c;--ion-color-medium-rgb: 146, 148, 156;--ion-color-medium-contrast: #ffffff;--ion-color-medium-contrast-rgb: 255, 255, 255;--ion-color-medium-shade: #808289;--ion-color-medium-tint: #9d9fa6;--ion-color-light: #f4f5f8;--ion-color-light-rgb: 244, 245, 248;--ion-color-light-contrast: #000000;--ion-color-light-contrast-rgb: 0, 0, 0;--ion-color-light-shade: #d7d8da;--ion-color-light-tint: #f5f6f9}
.coachTitle{font-family:"Kalam";font-size:20px;color:#fff}.coachArrow{margin-top:1px;margin-right:16px;margin-left:16px}.disable-select{user-select:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;-webkit-touch-callout:none}
