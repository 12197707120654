.Home {
}

.homeBackground {
	//background-image: url('../assets/home/forest.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.metalBackground {
	background-repeat: no-repeat;
	//margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
	//background: url('../assets/home/metal.jpg') 0 0/100% 100% no-repeat;
}
.blended {
	mix-blend-mode: hard-light;
}
.blendedOver {
	mix-blend-mode: screen;
}

.welcome {
	font-family: 'MarkProLight';
	font-size: 24px;
	color: #3f310e;
}
.wellness {
	font-family: 'MarkProLight';
	font-size: 30px;

	margin-top: 5px;
	color: #3f310e;
	line-height: 0.5;
}

.maori p {
	font-family: 'MrsEavesItalic';
	font-size: 28px;
	color: #3f310e;
	line-height: 1;
	margin: 8px;

	margin-left: 0px;
	margin-right: 0px;
}
.engraved {
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	text-shadow: -0.5px 0.5px 0px #ffe297, 0.5px -0.5px 0px #2b1c07;
}
.homeBtn {
	font-family: 'MarkProMedium';
	text-transform: none;
	padding-bottom: 8px;
	border-width: 0 0 2px;
	border-style: solid;
	border-color: #43340c;
	font-size: 26px;
	color: #43340c;
}
.newHomeBtn {
	font-family: 'MarkProMedium';
	text-transform: none;
	padding-bottom: 8px;
	border-width: 0 0 2px;
	border-style: solid;
	border-color: #ffe297;
	font-size: 22px;
	color: #ffe297;
	text-align: center;
	//width:100px;
}
.homeIcon {
	margin-right: 12px;
	color: #604709;
	fill: #604709;
}
.homeBtnOver {
	border-color: #ffe297;
	text-transform: none;
	font-size: 26px;
	color: #8f6b0f;
}
.newHomeBtnOver {
	border-color: #ffe297;
	text-transform: none;
	font-size: 22px;
	color: #8f6b0f;

}
.preloadingText {
	margin-top: 16px;
	margin-bottom: 5px;
	font-family: 'MarkProMedium';
	color: 'white';
}

#loadingcontainer {
	position: relative;
}

#loadingcontainer img {
	position: absolute;
	//top: -100px;
	//left: -140px;
	opacity: 0;
}

#loadingcontainer img:first-child {
	opacity: 1;
}
