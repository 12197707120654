.factsBackgroundDesktop {
	//background-image: url('../assets/products/productback.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}

.factsTitle {
	font-family: 'MarkProBook';
	text-transform: none;
	padding-bottom: 6px;
	border-width: 0 0 1.5px;
	border-style: solid;
	font-size: 20px;
	border-color: #d2b55e;
	text-transform: uppercase;
	color: '#544112';
}
.factsContent h2 {
	font-family: 'MarkProMedium';
	font-size: 16px;
	padding-bottom: 6px;
	color: #d2b55e;
}
.factsContent p {
	font-family: 'MarkProLight';
	font-size: 16px;
	padding-bottom: 6px;
}
.factsContent {
	font-family: 'MarkProLight';
	font-size: 16px;
}
.factsCard {
	z-index: 999;
}
.factsHeroImage {
}
.factsHeroTitle {
	font-family: 'MarkProBook';
	font-size: 35px;
	text-align: center;
	color: '#43340C';
	padding-left: 24px;
	padding-right: 24px;
}

ol {
	list-style: none;
	padding-left: 16px;
	counter-reset: olNum;
}
ol li::before {
	counter-increment: olNum;
	content: counter(olNum);
	color: #d2b55e;
	display: inline-block;
	width: 1em;
	margin-left: -1.5em;
	margin-right: 0.5em;
	text-align: right;
	direction: rtl;
}
.carousel-item {
	//height: 120px;
	//background-color: #777;
	//display: flex;
	width: 100%;
	//height: auto;
	flex: 1;
}
.carousel-item > img {
	//position: absolute;
	//top: 0;
	//right: 0;
	//min-width: 100%;
	//height: 120px;
	//object-fit: cover;
	//overflow: hidden;
	//object-position: -50% -50%;
}
