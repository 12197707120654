.scienceTitle {
	font-family: 'MarkProMedium';
	//text-transform: none;
	font-size: 26px;
	padding-bottom: 10px;
	color: '#FFE297';
}
.scienceBody p {
	font-family: 'MarkProLight';
	//text-transform: none;
	font-size: 16px;
	margin-bottom: 10px;
	color: '#FFE297';
}
.bookBackground {
	//background-image: url('../assets/book/bookbackground.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.scienceBackground {
	//background-image: url('../assets/science/sciencebackground2.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.scienceIconBackground {
	background-color: 'green';
	background-image: url('../assets/science/scienceiconback.png');
	background-repeat: no-repeat;
	width: 200px;
	height: 200px;
	background-position: center;
	background-size: contain;
	justify-content: center;
	align-items: center;
}
.scienceIcon {
	position: absolute;
	width: 74px;
	margin-top: 44px;
	margin-left: 64px;
}
.bookIcon {
	position: absolute;
	width: 84px;
	margin-top: 50px;
	margin-left: 58px;
}
.scienceCircle {
	position: absolute;
	margin-top: -33px;
	margin-left: -33px;
	//left: 100px;
	width: 266px;

	justify-content: center;
	align-items: center;
}
