* {
	box-sizing: border-box;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	touch-action: pan-x pan-y;
}

body {
	background: #ffffff;
	overflow: hidden;
	overscroll-behavior-y: none;
	touch-action: pan-x pan-y;
}

.loading {
	padding: 10px;
	transform: translate3d(-50%, -50%, 0);
}
