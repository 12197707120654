.cartTitle {
	font-family: 'MarkProMedium';
	//text-transform: none;
	font-size: 20px;
	padding-bottom: 10px;
	color: '#544112';
	text-transform: uppercase;
}

.cartCountMessage {
	font-family: 'MarkProLight';
	color: '#808080';
	text-transform: none;
	font-size: 16px;
}

.cartLineItemTitle {
	margin-top: 10px;
	font-family: 'MarkProMedium';
	color: 'black';
	font-size: 16px;
}
.cartLineItemSize {
	font-family: 'MarkProMedium';
	color: '#808080';
	font-size: 16px;
}
.cartLineItemPrice {
	font-family: 'MarkProMedium';
	color: '#808080';
	font-size: 16px;
}
.cartLineItemCount {
	font-family: 'MarkProMedium';
	color: '#D1AC68';
	font-size: 16px;
}
.cartLineItemTotal {
	//display: 'flex';
	//flex: 1;
	margin-top: 16px;
	margin-bottom: 5px;
	font-family: 'MarkProMedium';
	color: '#808080';
	font-size: 16px;
}
.cartLineItemTotalPrice {
	//display: 'flex';
	//flex: 1;
	margin-top: 16px;
	margin-bottom: 5px;
	font-family: 'MarkProMedium';
	color: 'black';
	font-size: 18px;
}
.cartLine {
	padding-bottom: 10px;
	border-width: 0 0 1.5px;
	border-style: solid;
	border-color: #d2b55e;
}
.cartItemLine {
	padding-bottom: 10px;
	border-width: 0 0 1px;
	border-style: solid;
	border-color: #e6e6e6;
}

.cartCountCircle {
	width: '20px';
	height: '20px';
	flex-direction: 'column';
	padding: '5px';
	border-radius: '20px';
	background-color: 'red';
}

.cartCountText {
	font-family: 'MarkProMedium';
	color: '#D1AC68';
	font-size: 16px;
}
