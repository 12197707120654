.closeBtn {
	font-family: 'MarkProMedium';
	text-transform: none;
	padding-bottom: 8px;
	border-width: 0 0 2px;

	border-style: solid;
	border-color: #544112;
	font-size: 26px;
	color: #604709;
}
.closeIcon {
	//margin-right: 12px;
	color: #604709;
	fill: #604709;
}
.closeBtnOver {
	border-color: #ffe297;
	text-transform: none;
	font-size: 26px;
	color: #8f6b0f;
}

.videoHolderSideBar {
	//top: 0;
	//left: 0;
	//width: 100%;
	//height: 236px;
	//margin-bottom: 20px;
}
.videosBackground {
	//background-image: url('../assets/products/productback.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.videoTitle {
	font-family: 'MarkProBook';
	text-transform: none;
	text-align: center;
	padding-bottom: 8px;
	border-width: 0 0 1.5px;
	border-style: solid;
	font-size: 18px;
	color: #d1ac68;
	border-color: #ffffff;
	margin-left: 8px;
	margin-right: 8px;
	//margin-bottom: 32px;
	//padding-top: 64px;
}
.videoFrame {
	//background-image: url('../assets/science/4x/videoframe@4x.png');
	//background-image: url('../assets/science/4x/sciencebee@4x.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
	//filter: drop-shadow(8px 8px 4px #00000060);
}
.videoBackBee {
	background-image: url('../assets/science/4x/sciencebee@4x.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.videoBackComb {
	background-image: url('../assets/science/4x/sciencecomb@4x.jpg');
	background-repeat: no-repeat;
	margin: 0px;
	width: '100%';
	height: '100%';
	background-position: center;
	background-size: cover;
}
.mainTitle {
	font-family: 'MarkProBook';
	text-transform: none;
	padding-bottom: 6px;
	border-width: 0 0 1.5px;
	border-style: solid;
	font-size: 24px;
	margin: 32px;

	border-color: #d2b55e;
	text-transform: uppercase;
	color: '#544112';
}

.metalTitle {
	font-family: 'MarkProMedium';
	text-transform: none;
	padding-bottom: 6px;
	border-width: 0 0 1.5px;
	border-style: solid;

	margin-top: 16px;
	margin-left: 32px;
	margin-right: 32px;
	margin-bottom: 16px;
	border-color: #3f310e;
	//text-transform: uppercase;
	color: #3f310e;
}

.metalBody {
	font-family: 'MrsEavesItalic';

	margin-left: 32px;
	margin-right: 32px;

	color: #3f310e;
}
